<template>
  <div>
    <div class="mrb20 bg-white pd20">
      <div class="mrb20">售后订单列表</div>
      <div>
        <el-table ref="multipleTable" border :data="tableData" tooltip-effect="dark" style="width: 100%">
          <el-table-column label="订单ID" prop="order_id" width="80"></el-table-column>

          <el-table-column label="退款订单编号" prop="refund_sn" width="190"> </el-table-column>

          <!-- 0申请中  1仅退款同意 2拒绝  3发货同意等待客户寄物流 4客户已寄等待签收 5已签收退款完成 6用户撤销申请 -->

          <el-table-column label="状态" width="190" align="center">
            <template slot-scope="scope">
              {{scope.row.status_desc}}
            </template>
          </el-table-column>

          <el-table-column label="退款金额" prop="amount" width="140">
            <template slot-scope="scope">
              {{scope.row.amount}}元
            </template>

          </el-table-column>

          <el-table-column label="售后类型" prop="refund_type_desc" width="180">
          </el-table-column>

          <el-table-column prop="created_at" label="申请时间" width="160">
          </el-table-column>

          <el-table-column prop="refund_logistics_company" label="物流公司" width="160">
          </el-table-column>

          <el-table-column prop="refund_logistics_no" label="物流单号" width="160">
          </el-table-column>

          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">

              <el-button size="mini" type="info" @click="log(scope.row.order_id)">操作日志</el-button>
              <el-button size="mini" type="primary" @click="detail(scope.row.order_id)">详情</el-button>

              <el-button size="mini" type="success" v-if="scope.row.status == 0 && scope.row.refund_type == 0" @click="action(scope.row.id,1)">同意退款？</el-button>
              <el-button size="mini" type="success" v-if="scope.row.status == 0 && scope.row.refund_type == 2" @click="action(scope.row.id,3)">同意请求,让他寄回来</el-button>

              <el-button size="mini" type="success" v-if="scope.row.status == 4" @click="action(scope.row.id,4)">已签收，同意退款？</el-button>

              <el-button size="mini" type="danger" v-if="scope.row.status == 0" @click="action(scope.row.id,2)">拒绝？</el-button>

            </template>
          </el-table-column>
        </el-table>

        <div class="mrt20 flex justify-end">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="pageInfo.page" :page-sizes="[10, 20, 50]" :page-size="pageInfo.limit" layout="total,sizes, prev, pager, next, jumper" :total="pageInfo.count">
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 操作日志 -->
    <el-dialog title="操作日志" :visible.sync="originalLogVisible" width="60%">

      <el-table ref="multipleTable" border :data="logData" tooltip-effect="dark" style="width: 100%">

        <el-table-column prop="created_at" label="申请时间" width="160">
        </el-table-column>

        <el-table-column label="订单ID" prop="order_id" width="80"></el-table-column>

        <el-table-column label="标题" prop="title" width="200"> </el-table-column>

        <el-table-column label="内容" prop="content">
        </el-table-column>

        <el-table-column label="操作者" prop="user_type_desc" width="80">
        </el-table-column>

      </el-table>

    </el-dialog>

  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      pageInfo: {
        limit: 10,
        page: 1,
        count: 0,
        pageNum: 0,
      },
      tableData: [],
      logData: [],
      originalLogVisible: false,
    };
  },

  created() {
    this.getList(this.pageInfo);
  },
  mounted() {},
  computed: {},

  methods: {
    action(id, type) {
      let text = "";
      if (type == 1) {
        text = "同意";
      }
      if (type == 2) {
        text = "拒绝";
      }

      if (type == 3) {
        text = "同意请求,让他寄回来";
      }

      if (type == 4) {
        text = "同意请求,快递已签收,同意退款";
      }

      this.$confirm("是否" + text + "该退款申请", "提示", {
        confirmButtonText: "确定" + text,
        cancelButtonText: "取消操作",
        type: "warning",
      })
        .then(() => {
          this.refund_action(id, type);
        })
        .catch(() => {});
    },

    // 退款请求
    async refund_action(id, type) {
      const { code, msg } = await this.$Api.refundOrderAction({
        id: id,
        type: type,
      });

      if (code == 200) {
        this.$message.success(msg);
      } else {
        this.$message.error(msg);
      }
      this.getList(this.pageInfo);
    },

    log(order_id) {
      this.logData = [];
      this.originalLogVisible = true;
      this.get_log(order_id);
    },
    detail(id) {
      this.$router.push({
        path: "/order/orderDetail?id=" + id,
      });
    },

    async get_log(order_id) {
      const { data } = await this.$Api.refundLogList({ order_id: order_id });
      this.logData = data;
    },

    async getList(pageInfo) {
      const { data } = await this.$Api.refundOrderList(pageInfo);
      this.tableData = data.list;
      this.pageInfo.limit = data.limit;
      this.pageInfo.page = data.page;
      this.pageInfo.count = data.count;
      this.pageInfo.pageNum = data.pageNum;
    },

    handleSizeChange(val) {
      console.log("val", val);
      this.pageInfo.limit = val;
      this.pageInfo.page = 1;
      this.getList(this.pageInfo);
    },
    handleCurrentChange(val) {
      console.log("val", val);
      this.pageInfo.page = val;
      this.getList(this.pageInfo);
    },
  },
};
</script>
<style lang='scss' scoped>
.edit_box {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffff;
}
.primary {
  color: rgb(2, 0, 146);
  font-weight: 800;
}
.success {
  color: rgb(2, 119, 41);
  font-weight: 800;
}
.danger {
  color: red;
  font-weight: 800;
}
</style>